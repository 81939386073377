if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || 
                                Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
Element.prototype.closest = function(s) {
    var el = this;

    do {
    if (el.matches(s)) return el;
    el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
};
}

import { AutomatitCarousel } from './automatit_carousel.m';
import { setState } from './util/stateHelper';

export const initFacInfoToggle = () => {
    document.querySelector('#mobile_facility_toggle').addEventListener('click', function() {
        this.classList.toggle('facility_info_open');
        document.querySelector('.facility_header_wrap').classList.toggle('facility_info_open');
    })
}

export const initFacCaro = () => {
    AutomatitCarousel({
		element: document.getElementById('facility_slider'),
		speed:700,
		intervalTiming: 8000,
		showDots:true
    })
}

export const initFilters = () => {
    const filters = document.querySelectorAll('#facility_unit_filter button');
    const rows = document.querySelectorAll('.facility_rates_row');
    const noUnitsMessage = document.querySelector('.no_units_message');

    document.querySelector('#facility_unit_filter').addEventListener('click', e => {
        const clickedFilter = e.target.closest('button');

        if(clickedFilter) {
            setState(noUnitsMessage, 'hide')
            if(clickedFilter.dataset.state == "on") {
                setState(clickedFilter, 'off');
            } else {
                filters.forEach(i => setState(i, 'off'))
                setState(clickedFilter, 'on');
            }

            const activeFilter = document.querySelector('#facility_unit_filter button[data-state="on"]');
            let rowCount = 0;

            rows.forEach(i => {
                setState(i, 'hide')
                if(!activeFilter || activeFilter.dataset.size == "all" || activeFilter.dataset.size == i.dataset.size) {
                    setState(i, 'show')
                    rowCount++;
                }
            })

            if(!rowCount) {
                setState(noUnitsMessage, 'show')
            }
        }
    })
}

export const initTour = () => {
    const overlay = document.querySelector('#virtual_tour');
    document.querySelector('#open_virtual_tour').addEventListener('click', () => {
        setState(overlay, 'open')
    })
    document.querySelector('#virtual_tour').addEventListener('click', e => {
        if(e.target.id == 'virtual_tour' || e.target.id == 'close_tour') {
            setState(overlay, 'closed')
        }
    })
}