import { initMap } from './allPages'
import mobileHeaderToggle from './header'
import { initHeroCarousel, initReviewsCarousel } from './home';
import { initFacInfoToggle, initFacCaro, initFilters, initTour } from './facility'
import { contactSubmit } from './contact';

mobileHeaderToggle();

if(document.querySelector('#multi_map')) {
	initMap();
}

if(document.querySelector('.home_hero_slider_wrap')) {
	initHeroCarousel();
}

if(document.querySelector('#home_reviews_carousel')) {
	initReviewsCarousel();
}

if(document.querySelector('#mobile_facility_toggle')) {
	initFacInfoToggle();
}

if(document.querySelector('#facility_slider')) {
	initFacCaro();
}

if(document.querySelector('#facility_unit_filter')) {
	initFilters();
}

if(document.querySelector('#virtual_tour')) {
	initTour();
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}
