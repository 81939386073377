import { getMapsHelper } from './util/mapsHelper';

export const initMap = () => {
    const mapsHelper = getMapsHelper();
    mapsHelper.ready()
        .then(() => {
            const homeMap = mapsHelper.createMap({
                element: document.querySelector('#multi_map'),
                locationElementSelector: '.data_source',
                useRichmarker: true,
                markerReducer: (el) => {
                    return {
                        lat: el.getAttribute('data-lat'),
                        lng: el.getAttribute('data-lng'),
                        content: `<a class="map_pin" ${(el.getAttribute('data-blank') == "true")?'target="_blank"':''} href="${el.getAttribute('data-link')}"></a>`,
                    }
                }
            })
            google.maps.event.addListenerOnce(homeMap, 'idle', () => {
				if(document.querySelectorAll('.data_source').length < 2) {				
					homeMap.setZoom(15);
				}
            });
        })
}