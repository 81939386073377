import { AutomatitCarousel } from './automatit_carousel.m';

export const initHeroCarousel = () => {
    let heroImgs = [
        {src: "/static/images/backgrounds/img_hero.jpg", alt: ""},
        {src: "/static/images/backgrounds/img_hero2.jpg", alt: ""},
    ]
    AutomatitCarousel({   
        element: document.getElementById('home_hero_slider'),
        speed:700,
        intervalTiming: 8000,
        showDots:true,
        images: heroImgs,
        imagesAsBackgrounds: true
    })
}

export const initReviewsCarousel = () => {
    AutomatitCarousel({
		element: document.querySelector('#home_reviews_carousel'),
		speed:700,
		intervalTiming: false
    })
}